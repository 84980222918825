<template lang="pug">
.mt-2.mr-2.ml-2
  .d-flex
    v-btn(
      color="primary",
      icon,
      small,
      @click="() => $router.back()"
    )
      v-icon(center) mdi mdi-arrow-left

    .mt-2(style="font-size: 24px; font-weight: 500") {{ itemConsulta.id + " - " + itemConsulta.nome }}
  v-divider
  v-tabs(v-model="activeTab", color="primary")
    v-tab(key="1") Consulta
    v-tab(key="2") DashBoard

    v-tab-item(key="1")
      .mt-2()
        v-row
          v-col(cols="4")
            v-select.mt-1(
              v-model="configSelected",
              label="Cenários",
              placeholder="Selecione ou crie um cenários",
              persistent-placeholder,
              :items="configs",
              item-text="nome",
              item-value="id",
              no-data-text="Nenhum cenário cadastrado",
              return-object,
              outlined,
              dense,
              hide-details,
              @change="selectCenario"
            )
              template(v-slot:prepend-item)
                v-list-item(
                  dense,
                  ripple,
                  @mousedown.prevent,
                  @click="newCenario"
                )
                  v-list-item-action
                    v-icon mdi mdi-plus
                  v-list-item-content
                    v-list-item-title Novo Cenário
                v-divider 

          v-col(cols="1")
            v-btn.ml-0(
              :disabled="configSelected.id === 0",
              color="primary",
              outlined,
              @click="cloneMode = !cloneMode; dialogCadastroCenarios = true"
            ) 
              v-icon(left) mdi mdi-text-box-multiple-outline
              span Clonar

          v-col.text-right(cols="3")

          v-col.text-right(cols="4")
            v-btn.ml-2(
              :disabled="configSelected.id === 0",
              color="primary",
              outlined,
              @click="dialogCadastroCenarios = true"
            ) 
              v-icon(left) mdi mdi-pencil-outline
              span Atualizar

            v-btn.ml-2(
              :disabled="configSelected.id === 0",
              color="error",
              outlined,
              @click="dialogExcluir = true"
            ) 
              v-icon(left) mdi mdi-delete-outline
              span Deletar

        .mt-2(v-if="dataset.length === 0") Nenhum resultado encontrado nesta consulta
        div(class="ma-2" style="font-weight: 400") {{ configSelected.descricao }}
        
        PivotTable(
          v-model="configRetorno",
          :dataset="dataset",
          :rows="configSelected.configuracao.rows",
          :cols="configSelected.configuracao.cols",
          :vals="configSelected.configuracao.vals",
          :aggregator-name="configSelected.configuracao.aggregatorName",
          :renderer-name="configSelected.configuracao.rendererName",
          :rowOrder="configSelected.configuracao.rowOrder",
          :colOrder="configSelected.configuracao.colOrder",
          :value-filter="configSelected.configuracao.valueFilter"
          style="height: calc(100vh - 295px); overflow-y: auto; overflow-x: auto;"
        )

    v-tab-item.mt-2(key="2")
      v-card.ma-2(v-for="(row, i) in configs", :key="i", elevation="3") 
        v-card-title.pt-2(style="display: block; text-align: left")
          .d-flex
            div {{ row.nome }}
            div
              v-icon.pt-0.mt-0(
                color="primary",
                right,
                size="22px",
                @click="editCenario(row)"
              ) mdi mdi-pencil-outline

          div(style="font-size: 15px; color: #666") {{ row.descricao }}

        v-card-text.pa-4.pt-0(style)
          PivotTable(
            :manipulate="false",
            :dataset="dataset",
            :rows="row.configuracao.rows",
            :cols="row.configuracao.cols",
            :vals="row.configuracao.vals",
            :aggregator-name="row.configuracao.aggregatorName",
            :renderer-name="row.configuracao.rendererName",
            :rowOrder="row.configuracao.rowOrder",
            :colOrder="row.configuracao.colOrder",
            :value-filter="row.configuracao.valueFilter"
          )

  //- Dialog com titulo e descrição para gravar o cenário
  v-dialog(
    v-model="dialogCadastroCenarios",
    v-if="dialogCadastroCenarios",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Incluir" : "Atualizar" }} Cenário
      v-card-text
        v-text-field.mt-1(
          label="Nome do Cenário",
          v-model="configSelected.nome",
          outlined,
          maxlength="80",
          counter,
          dense,
          required,
          :rules="rules"
        )
        v-textarea.mt-2(
          label="Descrição do Cenário",
          v-model="configSelected.descricao",
          outlined,
          maxlength="200",
          counter,
          dense,
          required,
          :rules="rules"
        )
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="cancelaCadastroCenarios()") Cancelar
        v-btn(color="primary", outlined, @click="gravarCenario()") 
          span {{ cloneMode ? "Clonar" : configSelected.id === 0 ? "Salvar" : "Atualizar" }}

  //- Dialog para exclusão de cenário
  v-dialog(
    v-model="dialogExcluir",
    v-if="dialogExcluir",
    persistent,
    max-width="600"
  )
    v-card
      v-card-title
        h3 Deletar Cenário
      v-card-text
        h5 Tem certeza que deseja deletar o cenário - {{ configSelected.nome }}?
      v-card-actions.pr-0.pt-0.mt-0
        v-spacer
        v-btn(color="primary", outlined, @click="dialogExcluir = false") Cancelar
        v-btn(color="error", outlined, @click="deletarCenario") Deletar
</template>

<script>
import { mapMutations } from "vuex";
import csapi from "@/api/csapi";

import PivotTable from "@/components/PivotTable.vue";

export default {
  name: "view_resultado",
  components: {
    PivotTable,
  },
  data() {
    return {
      initConfig: {
        rowOrder: "key_a_to_z",
        colOrder: "key_a_to_z",
        valueFilter: {},
        rows: [],
        cols: [],
        rendererName: "Tabela",
        aggregatorName: "Soma",
        aggregators: {},
        vals: [],
      },
      activeTab: 0,
      itemConsulta: {},
      dataset: [],
      cloneMode: false,
      dialogCadastroCenarios: false,
      dialogExcluir: false,
      nomeForFind: "",
      configRetorno: {},
      configSelected: {
        id: 0,
        nome: "",
        descricao: "",
        datahun_id: 0,
        configuracao: {
          rowOrder: "key_a_to_z",
          colOrder: "key_a_to_z",
          valueFilter: {},
          rows: [],
          cols: [],
          rendererName: "Tabela",
          aggregatorName: "Soma",
          aggregators: {},
          vals: [],
        },
      },
      configs: [],
      rules: [(v) => !!v || "Campo obrigatório"],
    };
  },

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),
    ...mapMutations("loader", ["showLoader", "hideLoader"]),

    permissoesAcesso() {
      const roles = JSON.parse(JSON.stringify(this.$store.state.auth.user.roles));
      const role = roles.filter((role) => role.projectid == 257)

      return role.length !== 0
    },

    cancelaCadastroCenarios() {
      this.dialogCadastroCenarios = false;
      this.cloneMode = false;

      this.getCenarios();
    },

    async getData() {
      this.showLoader();
      try {
        const { status, data } = await csapi.get(`/v1/datahub/${this.$route.params.id}/resultado`);

        if (!status.toString().startsWith("2")) {
          throw new Error('Problema ao carregar os dados, tentar novamente');
        }

        this.dataset = data.map(item => {
          const { _id, datahub_id, __v, processed_at, createdAt, updatedAt, ...rest } = item;
          return { ...rest, criado_em: new Date(createdAt).toLocaleString() };
        });
      } catch (e) {
        const errorMessage = e.response.status === 404 ? 'Consulta não está disponivel' : 'Problem ao carregar os dados, consulte o suporte';
        this.$toast.error(errorMessage);
        if (e.response.status === 404) {
          this.$router.back();
        }
      } finally {
        this.hideLoader();
      }
    },

    async getConsultaSelecionada() {
      try {
        const link = `/v1/datahub/${this.$route.params.id}`;
        const res = await csapi.get(link);

        if (res.status.toString().startsWith("2")) {
          this.itemConsulta = res.data;
        }
      } catch (e) {
        console.error("e", e);
      }
    },

    async getCenarios() {
      try {
        const link = `/v1/datahub/${this.$route.params.id}/cenarios`;
        let { status, data } = await csapi.get(link);

        if (!status.toString().startsWith("2")) {
          throw new Error('Problema ao carregar os cenários, tentar novamente');
        }

        if (data.length === 0) {
          await this.gravarCenario(true);
          const res2 = await csapi.get(link);
          data = res2.data;
        }

        this.configs = data;
        this.configSelected = this.configs.find(item => item.nome === this.nomeForFind) || this.configs[0];
        this.nomeForFind = "";
      } catch (e) {
        this.$toast.error("Problem ao carregar os cenários, consulte o suporte");
      }
    },

    editCenario(item) {
      this.configSelected = item;
      this.activeTab = 0;
    },

    async gravarCenario(notScenario = false) {
      if (notScenario) {
        this.configSelected.nome = "Cenário padrão";
        this.configSelected.descricao = "Para começar a montar o seu cenário, arraste os campos para as áreas de linhas e colunas. Em seguida selecione o tipo de visualização (Tabelas ou Gráficos) e a forma de agregação (Soma, Média, Contagem, etc). Por fim, clique em atualizar para gravar essa nova configuração.";
      }

      let res = null;
      let localCenario = JSON.parse(JSON.stringify(this.configSelected));
      localCenario.configuracao = this.configRetorno;

      const link =
        this.configSelected.id === 0 || this.cloneMode
          ? `/v1/datahub/${this.$route.params.id}/cenarios`
          : `/v1/datahub/${this.$route.params.id}/cenarios/${this.configSelected.id}`;

      try {
        if (this.cloneMode) localCenario.id = 0;

        res =
          this.configSelected.id === 0 || this.cloneMode
            ? await csapi.post(link, localCenario)
            : await csapi.put(link, localCenario);

        if (res.status.toString().startsWith("2")) {
          this.$toast.success(
            this.cloneMode
              ? "Cenário clonado com sucesso"
              : this.configSelected.id === 0
              ? "Cenário gravado com sucesso"
              : "Cenário atualizado com sucesso"
          );
        }

        this.nomeForFind = this.configSelected.nome;
      } catch (e) {
        console.error("e", e);
        this.$toast.error(
          "Problema ao gravar/atualizar o cenário, consulte o suporte"
        );
      } finally {
        this.cloneMode = false;
        this.dialogCadastroCenarios = false;
        this.configSelected.datahun_id = this.$route.params.id;
        this.getCenarios();
      }
    },

    async deletarCenario() {
      this.dialogExcluir = false;
      try {
        const link = `/v1/datahub/${this.$route.params.id}/cenarios/${this.configSelected.id}`;
        const { status } = await csapi.delete(link);

        if (!status.toString().startsWith("2")) {
          throw new Error('Problema ao deletar o cenário, tentar novamente');
        }

        this.$toast.success("Cenário deletado com sucesso");
      } catch (e) {
        this.$toast.error("Problema ao deletar o cenário, consulte o suporte");
      } finally {
        this.getCenarios();
        this.configSelected = {
          id: 0,
          nome: "",
          descricao: "",
          datahun_id: this.$route.params.id,
          configuracao: JSON.parse(JSON.stringify(this.initConfig)),
        };
      }
    },

    newCenario() {
      this.nomeForFind = this.configSelected.nome;

      this.configSelected = {
        id: 0,
        nome: "",
        descricao: "",
        datahun_id: this.$route.params.id,
        configuracao: JSON.parse(JSON.stringify(this.initConfig)),
      };

      this.cloneMode = false;
      this.dialogCadastroCenarios = true;
    },

    selectCenario(cenario) {
      this.configSelected = cenario;
    },
  },

  mounted() {
    this.setTitle("DataHub - Consulta");
    this.isControl(true);

    if (this.permissoesAcesso()) {
      this.getConsultaSelecionada();
      this.getCenarios();
      this.getData();
    } else {
      this.$toast.error("Você não tem permissão para acessar essa página");
      this.$router.back();
    }
  },
};
</script>